import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import { responseFilter } from "./responseFilter";
import { sampleAnswer } from "../../raw/Raw";

const fetchAnswer = createAsyncThunk("chatstore/fetch", async (data) => {
    
    const {searchterm, memory} = data;
   
  const response = await axios.get(
    "https://prod-toyota-modal.aidevlab.com/query/toyota?text=" +
      searchterm
  );

    // const response ={data:sampleAnswer} ;
  // DEV ONLY!!!
  await pause(1000);
    
  return responseFilter(response.data,searchterm);
});

// DEV ONLY!!!
export const pause = (duration) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

export { fetchAnswer };
