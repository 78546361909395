import React, { useRef, useState } from "react";
import AnimateText from "./AnimateText";
import avatar from "../assets/toyotaAvatar3.png";
import { Avatar, Pagination } from "@mui/material";
import { Typography } from "@mui/material";
import PopOverWraper from "./PopOverWraper";

const Answer = ({ info, last }) => {
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const childRef = useRef();

  const handleClick = (e) => {
    childRef.current.childFunction1(e);
  };
  return (


    <>
      {" "}
      <div className="mb-4 p-4 flex gap-x-3 md:gap-x-4 text-[18px]  text-[#333333bd]">
        <div className="cursor-pointer" 
        onDoubleClick={handleClick}
        >
          <Avatar sx={{ bgcolor: "red" }} className="bg-cover " src={avatar}>
            N
          </Avatar>
        </div>
        <div className="flex-1 whitespace-pre-wrap text-[15px] pr-[5px] md:pr-[40px] overflow-x-auto style-3">
          <AnimateText info={info.response} animate={last} />
        </div>
      </div>
      <PopOverWraper ref={childRef}>
        <Typography sx={{ p: 2, textAlign: "center" }}>
          {info.response.docs ? info.response.docs[page - 1] : ""}
        </Typography>
        <Pagination
          className="pb-[10px]"
          count={info.response.docs ? info.response.docs.length : 0}
          page={page}
          onChange={handleChange}
        />
      </PopOverWraper>
    </>
  );
};

export default Answer;
