import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isSigned: false,
    role: null,
    data: null,
    
  },
  reducers: {
    signedInUser(state, action) {
      const {username,password} = action.payload;
      if(username==="admin" && password==="ASKPOC22")
      {
        state.isSigned = true;
      }
      
    },
  },
});

export const { signedInUser } = userSlice.actions;
export default userSlice.reducer;
